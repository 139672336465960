import cx from 'classnames';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import useLinkProps from '../../../hooks/use-link-props';
import useLoggedUser from '../../../hooks/use-logged-user';
import { useAppContext } from '../../providers/app-context';
import BPImage from '../../ui/image';

const ProButton = ({ isMobile = false }: { isMobile?: boolean }) => {
  const getLink = useLinkProps();
  const { t } = useTranslation();
  const { shippingLocation } = useAppContext();
  const user = useLoggedUser();

  return (!user || (user && !user?.staff.is_pro)) &&
    shippingLocation &&
    [1, 2, 3, 11].includes(shippingLocation) ? (
    <div
      className={cx('flex', 'justify-center lg:justify-start', 'py-3 lg:py-0', {
        'w-full bg-blue-10 ': isMobile,
      })}
    >
      <Link
        {...getLink('pro')}
        className={cx(
          'group',
          { 'flex lg:hidden': isMobile },
          { 'hidden lg:flex mr-4': !isMobile },
          'justify-center items-center',
          'text-xs'
        )}
      >
        <span
          className={cx(
            'border border-neutral-70 group-hover:border-blue-100',
            'rounded-l-full',
            'pl-3 pr-2 py-0.5',
            'text-right',
            'bg-white group-hover:bg-blue-100',
            'text-neutral-70 group-hover:text-white'
          )}
        >
          {t('header:links.about-pro')}
        </span>
        <span
          className={cx(
            'border border-neutral-70 group-hover:border-blue-100 group-hover:border-l-white',
            'text-white',
            'px-2 py-0.5',
            'bg-neutral-70 group-hover:bg-blue-100',
            'rounded-r-full'
          )}
        >
          PRO
        </span>
      </Link>
    </div>
  ) : null;
};

export default ProButton;
