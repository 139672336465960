import { atom, useAtom } from 'jotai';

export const isSearchOpen = atom(false);

const closeSearch = atom(null, (get, set) => {
  set(isSearchOpen, false);
});

const openSearch = atom(null, (get, set) => {
  set(isSearchOpen, true);
});

export const useOpenSearch = () => {
  const [, action] = useAtom(openSearch);

  return action;
};

export const useCloseSearch = () => {
  const [, action] = useAtom(closeSearch);

  return action;
};
