import cx from 'classnames';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ComponentProps, ReactNode } from 'react';

import useLinkProps, { originalRoutes } from '../../../hooks/use-link-props';
import {
  navigationOpen,
  openNavigationToggle,
} from '../../../state/navigation';
import { ADVANCED_VIEW_ACTIVE } from '../../../static/cookies';

const SwitchButton = ({
  isActive = false,
  rounded,
  children,
  type,
}: {
  isActive?: boolean;
  rounded?: 'left' | 'right';
  children: ReactNode;
  type: 'normal' | 'advanced';
}) => {
  const { reload } = useRouter();
  const [isMenuOpen] = useAtom(navigationOpen);
  const [, toggleMenu] = useAtom(openNavigationToggle);

  return isActive ? (
    <span
      className={cx(
        'text-xs',
        'border',
        'px-3 py-0.5',
        'border-blue-100 text-blue-100 bg-blue-10',
        {
          'rounded-l-full': rounded === 'left',
          'rounded-r-full': rounded === 'right',
        }
      )}
    >
      {children}
    </span>
  ) : (
    <button
      onClick={() => {
        if (isMenuOpen) toggleMenu();
        Cookies.set(ADVANCED_VIEW_ACTIVE, type === 'advanced' ? '1' : '0');
        reload();
      }}
      className={cx(
        'text-xs',
        'border',
        'px-3 py-0.5',
        'hover:border-blue-100 hover:text-white hover:bg-blue-100',
        'border-neutral-50 text-neutral-50',
        {
          'rounded-l-full': rounded === 'left',
          'rounded-r-full': rounded === 'right',
          'border-r-0': rounded === 'left' && !isActive,
          'border-l-0': rounded === 'right' && !isActive,
        }
      )}
    >
      {children}
    </button>
  );
};

const Advancedswitch = () => {
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const isAdvanced = !!parseInt(Cookies.get(ADVANCED_VIEW_ACTIVE) || '0');

  return (
    <div className={cx('px-4')}>
      <SwitchButton isActive={!isAdvanced} rounded="left" type="normal">
        {t('common:normalView')}
      </SwitchButton>
      <SwitchButton isActive={isAdvanced} rounded="right" type="advanced">
        {t('common:proSearch')}
      </SwitchButton>
    </div>
  );
};

export default Advancedswitch;
