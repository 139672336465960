/* eslint-disable @next/next/no-img-element */
import cx from 'classnames';
import { useAtom } from 'jotai';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FunctionComponent, memo } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import useLoggedUser from '../../../hooks/use-logged-user';
import { openCartToggle, settingsModalToggle } from '../../../state/cart';
import { openNavigationToggle } from '../../../state/navigation';
import { useAppContext } from '../../providers/app-context';
import Button from '../../ui/button';
import BPImage from '../../ui/image';
import TextLink from '../../ui/links/text';
import Advancedswitch from '../../ui/switch/advanced';
import { IntercomPlaceHolder } from '../intercom';
import { useOpenSearch } from '../search/state';

import classes from './header.module.css';
import ProButton from './pro-button';

const CurrentLanguageCurrency = dynamic(
  () => import('./current-language-currency'),
  { ssr: false }
);

const Intercom = dynamic(() => import('../intercom'), {
  ssr: false,
  loading: () => <IntercomPlaceHolder />,
});

const CurrentShippingMobile = dynamic(
  () => import('./current-shipping-mobile'),
  { ssr: false }
);

const CurrentCartProducts = dynamic(() => import('./current-cart-products'), {
  ssr: false,
});

const Header = memo(() => {
  const { t } = useTranslation();
  const getLink = useLinkProps();
  const [, toggleMenu] = useAtom(openNavigationToggle);
  const [, toggleCart] = useAtom(openCartToggle);
  const [, toggleSettings] = useAtom(settingsModalToggle);
  const openSearch = useOpenSearch();
  const {
    tenantConfig,
    concent: { required },
  } = useAppContext();
  const user = useLoggedUser();

  return (
    <>
      <ProButton isMobile={true} />
      <header className="bg-white">
        <div
          className={cx(
            classes.header,
            'container',
            'm-auto',
            'grid',
            'items-center'
          )}
        >
          <div className="flex">
            <Button
              isFullWidth={false}
              isSmall={true}
              className={cx(
                'h-12',
                'w-12',
                'flex justify-center items-center',
                'print:hidden'
              )}
              disableFocusOnMenu
              onClick={toggleMenu}
              data-cy="btn-menu"
            >
              <BPImage
                width={16}
                height={16}
                layout="fixed"
                src={`${process.env.PUBLIC_PREFIX}/svg/icons/menu.svg`}
                alt={t('common:searchAlt')}
              />
              {required && <CurrentShippingMobile />}
            </Button>
            <Button
              isFullWidth={false}
              isSmall={true}
              className={cx(
                'h-12',
                'w-12',
                'flex justify-center items-center',
                'print:hidden'
              )}
              disableFocusOnMenu
              onClick={openSearch}
              cy-data="free-search-btn"
            >
              <BPImage
                width={16}
                height={16}
                layout="fixed"
                src={`${process.env.PUBLIC_PREFIX}/svg/icons/search.svg`}
                alt={t('common:searchAlt')}
              />
            </Button>
            <div
              className={cx('justify-self-start', 'hidden', 'lg:block', {
                'col-span-2': !user?.staff.is_pro,
              })}
            >
              <Button
                isFullWidth={false}
                className={cx(
                  'flex justify-center items-center',
                  'print:hidden'
                )}
                disableFocusOnMenu
                onClick={toggleSettings}
              >
                {required && <CurrentLanguageCurrency />}
              </Button>
            </div>
          </div>

          <div className="flex flex-1 justify-center items-center">
            <div
              className={cx(
                tenantConfig.id === 1 ? 'w-32 lg:w-44' : 'w-32 lg:w-96',
                'h-6',
                'lg:h-8',
                'showForPrint'
              )}
            >
              <Link
                {...getLink('index')}
                passHref
                prefetch={false}
                shallow
                legacyBehavior
              >
                <TextLink>
                  <picture>
                    {tenantConfig.logo?.sources?.map((config, idx) => (
                      <source key={`logoSource${idx}`} {...config} />
                    ))}
                    <img
                      src={tenantConfig.logo.img}
                      alt={t('common:logoAlt')}
                      className={cx('showForPrint', 'mx-auto', 'h-full')}
                      style={{
                        ...(tenantConfig.logo.height && {
                          height: tenantConfig.logo.height,
                        }),
                        ...(tenantConfig.logo.width && {
                          width: tenantConfig.logo.width,
                        }),
                      }}
                    />
                  </picture>
                </TextLink>
              </Link>
            </div>
          </div>

          <div className="flex justify-end print:hidden">
            <ProButton />
            {user?.staff.is_pro && (
              <div className="hidden lg:flex items-center">
                <Advancedswitch />
              </div>
            )}
            <Intercom />
            <Button
              isFullWidth={false}
              isSmall={true}
              className={cx('h-12', 'w-12', 'flex justify-center items-center')}
              onClick={toggleCart}
              disableFocusOnMenu
              data-cy="btn-cart"
            >
              <BPImage
                width={16}
                height={16}
                layout="fixed"
                src={`${process.env.PUBLIC_PREFIX}/svg/icons/cart.svg`}
                alt={t('common:cartAlt')}
              />
              {required && <CurrentCartProducts />}
            </Button>
          </div>
        </div>
      </header>
    </>
  );
});

Header.displayName = 'Header';
export default Header;
